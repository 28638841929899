@keyframes rollout {
  0% { transform: rotate(0deg);}
  100% { transform: rotate(180deg); }
}

@keyframes rollin {
  0% { transform: translateY(150%);}
  100% { transform: translateY(0); }
}

.card-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.card-image {
  height: 70vh;
  width: auto;
  animation: rollout 1s;
}

.card-rule {
  margin: 1vh;
  text-align: center;
  color: white;
  font-size: 4vh;
  animation: rollin 1s;
}