.home-menu {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.play-again-banner {
  height: 30vh;
  max-width: 95vw;
  width: auto;
  margin-top: 3vh;
}

.play-again-btn {
  height: 12vh;
  width: auto;
  margin: 2vh;
}

.menu-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}