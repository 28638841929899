.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: rgb(58, 58, 58);
}

.game-title {
  color: white;
  margin: 2vh;
  font-size: 5vh;
}