.footer-container{
  display: flex;
  justify-content: center;
  width: 100vw;
  margin: 1vh;
}

.footerimg {
  height: 6vh;
  margin-left: 3vw;
}