.rules-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

.rules-banner {
  height: 30vh;
  max-width: 95vw;
  width: auto;
  margin-top: 3vh;
}

h4 {
  color: white;
}

.rules-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
  height: 60vh;
  width: 100vw;
}

li {
  margin: 1vh;
}